<template>
  <page title="订单详情" >
    
    <div class="bg-white p-4">
      <div class="mb-2"> 顺丰快递 </div>
      <div class="text-sm text-gray-light"> 单号：399203040349495 </div>
    </div>
    <div class="p-4 mt-2 flex bg-white items-start">
      <div class="flex-1">
        <div class="mb-2"> 
          <span class="font-medium">王小刚</span> 
          <span class="ml-2 text-sm text-gray-light">18829393343</span> 
        </div>
        <div class="text-sm leading-5"> 陕西省西安市未央区未央新城13栋1单元1202 </div>
      </div>
      <tag color="orange">已签收</tag>
    </div>
    <div class="bg-white mt-2">
      <media-item url="#" title="商品名称" note="数量：1 台" img-ratio="16/15" img-width="90" :image="TestData.slides[0].image">
        <div class="mt-1 text-xs text-gray-light">订单编号：20202225522222</div>
        <div class="flex flex-wrap mt-2">
          <tag color="green" class="mr-2">红色</tag>
          <tag color="red" class="mr-2">125G</tag>
          <tag color="blue" class="mr-2">全网通</tag>
        </div>
      </media-item>
    </div>

    <div class="mt-2">
      <cell>
        订单编号：20202225522222
        <btn slot="right" size="xs" text-size="xs"> 复制 </btn>
      </cell>
      <cell note="2020-12-20 15:07:15">下单时间</cell>
      <cell note="¥1199.00" note-class="text-red-500">商品金额</cell>
      <cell note="- ¥232.00" note-class="text-red-500">积分抵扣</cell>
      <cell note="- ¥78.00" note-class="text-red-500">优惠券</cell>
      <cell note="¥0.00" note-class="text-red-500">运费</cell>
    </div>
    
    <!-- <cart-bar count="24" slot="footer" @on-cart="onCart" @on-buy="onBuy"></cart-bar> -->
    <div class="bg-white h-12 flex items-center px-4" slot="footer">
      <div class="flex-1">
        <span class="text-sm">订单金额：</span>
        <span class="text-lg font-medium text-red-500"><span class="text-xs">¥</span> 3932</span>
      </div>
      <div class="flex items-center">
        <btn radius="full" color="green-500" size="sm" url="/return-sale/1">退货/售后</btn>
        <btn radius="full" color="orange-500" size="sm" class="ml-3" >再次购买</btn>
      </div>
    </div>
    
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
import { CartBar } from '@/components'
export default {
  components: {
    CartBar
  },
  data() {
    return {
      current: 0,
      showGoodsInfo: false,
      colorSelected: [],
      colorOptions: [],
      specSelected: [],
      specOptions: [],
      count: 0,
      showHeadBg: false,
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods: {
    tabChange(cur,index){
      this.current = index
    },
    clickHandler(item, index) {
      console.log(item);
    },
    onCart() {
      console.log('加入购物车');
      this.showGoodsInfo = true
    },
    onBuy() {
      console.log('购买');
      this.showGoodsInfo = true
    },
    onScroll(e) {
      if (e.y< -10) {
        this.showHeadBg = true
      }else{
        this.showHeadBg = false
      }
    },
    getGoodsInfo() {
      this.colorOptions = [
        {
          value: 1,
          text: '烟波蓝'
        },
        {
          value: 2,
          text: '静默星空'
        },
        {
          value: 3,
          text: '碧海星辰'
        },
        {
          value: 4,
          text: '湖光秋色'
        }
      ]
      this.specOptions = [
        {
          value: 1,
          text: '全网通128G + 6G'
        },
        {
          value: 2,
          text: '全网通8G+128G'
        },
        {
          value: 3,
          text: '全网通16G+128G'
        },
        {
          value: 4,
          text: '全网通32G+128G'
        }
      ]
    },
    onCountChange(type) {
      if (type == 'minus') {
        this.count --
      }else if (type == 'plus') {
        this.count ++
      }
      if (this.count < 0) this.count = 0
    }
  },
  created() {
    console.log(this.$route);
    this.getGoodsInfo()
  }
}
</script>

<style lang="stylus">

</style>